/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { startOfMonth, format } from 'date-fns';
import mbpUtil from 'mbp-api-util';
import { removeMaskForPhoneNumber } from '../../../Common/Common-Selectors';

// Review if there is HD(1019), SY(1018), WLF(1020) product in the cart.
export const googlePaySupportedBrand = (brandId) => !['1019', '1020', '1018'].includes(brandId);

// Plugin Config
export const googlePayPluginConfig = {
    sdkURL: mbpUtil.getEnv('APP_GOOGLEPAY_SDK_URL'),
    environment: mbpUtil.getEnv('APP_GOOGLEPAY_ENV'),
    merchantId: mbpUtil.getEnv('APP_GOOGLEPAY_MID'),
    merchantName: '1800flowersinc',
};

export const getOrderItemId = (data) => data?.recipients[0]?.cartItems[0]?.cartItemId || '';

export function getPaymentClient(paymentClientConfig) {
    let paymentClient = null;
    if (typeof window !== 'undefined') {
        paymentClient = new window.google.payments.api.PaymentsClient(paymentClientConfig);
    }
    return paymentClient;
}

export const getIsGooglePaySupported = async () => {
    let isSupported = false;
    const paymentClient = getPaymentClient({ environment: googlePayPluginConfig.environment });
    if (paymentClient) {
        const response = await paymentClient.isReadyToPay({
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
                {
                    type: 'CARD',
                    parameters: {
                        allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                        allowedCardNetworks: ['AMEX', 'DISCOVER', 'INTERAC', 'JCB', 'MASTERCARD', 'VISA'],
                    },
                },
            ],
        });
        if (response.result) {
            isSupported = true;
        }
    }
    return isSupported;
};

export function getGoogleTransactionInfo(orderSummary) {
    const { totalTax, orderTotalBeforeTaxes, orderTotalBalance } = orderSummary;
    return {
        displayItems: [
            {
                label: 'Total before tax',
                type: 'SUBTOTAL',
                price: `${orderTotalBeforeTaxes}`,
            },
            {
                label: 'Taxes',
                type: 'TAX',
                price: `${totalTax}`,
            },
        ],
        countryCode: 'US',
        currencyCode: 'USD',
        totalPriceStatus: 'FINAL',
        totalPrice: `${orderTotalBalance}`,
        totalPriceLabel: 'Order Total',
    };
}

/**
 * getfirstAndLastName function split the name by charater space
 */
export function getfirstAndLastName(name) {
    const splitName = name.split(' ');
    const returnName = {
        firstName: splitName[0] || '.',
        lastName: '.',
    };
    if (splitName.length > 1) {
        splitName.shift();
        returnName.lastName = splitName.join(' ');
    }
    return returnName;
}

export const normalizeGooglePaymentBillingAddress = (paymentData) => {
    const address = paymentData.paymentMethodData.info.billingAddress;
    const emailId = paymentData.email;
    const phoneNumber = removeMaskForPhoneNumber(address.phoneNumber);
    const fullName = getfirstAndLastName(address.name);
    const firstName = fullName.firstName;
    const lastName = fullName.lastName;

    return {
        firstName,
        lastName,
        address1: address.address1,
        address2: address.address2,
        state: address.administrativeArea,
        city: address.locality,
        country: address.countryCode,
        email: emailId,
        confirmEmail: emailId,
        phone: phoneNumber,
        mobilePhone: phoneNumber,
        zipCode: address.postalCode,
    };
};

export const getMonthStartDate = () => {
    const startDateOfMonth = startOfMonth(new Date());
    return format(startDateOfMonth, 'yyyy-MM-dd');
};

const defaultShippingOptionSelection = () => ({
    defaultSelectedOptionId: 'day=00&month=00&year=0001&deliverySLA=FPT',
    shippingOptions: [
        {
            id: 'day=00&month=00&year=0000&deliverySLA=FPT',
            label: 'Standard shipping',
            description: 'Standard Shipping',
        },
    ],
});

export const getDateDeliveryFormat = (dateSelected) => {
    const dateInfo = {};
    const arrDateProps = dateSelected.split('&&');
    arrDateProps.forEach((info) => {
        if (info.indexOf('=') > 0) {
            const keyValue = info.split('=');
            dateInfo[keyValue[0]] = keyValue[1];
        }
    });
    return dateInfo;
};

/**
 * @param {*} deliveryDate
 * Split Date by hypen "-" and returns year, month, day
 * "09/01/20201" returns { year: "2020", "month": "09", "day": "01"}
 */
const splitDateBySlash = (deliveryDate) => {
    const splitDate = deliveryDate.split('/');
    const month = parseInt(splitDate[0], 10) - 1;
    const day = parseInt(splitDate[1].trim(), 10);
    const year = parseInt(splitDate[2], 10);

    return {
        year,
        month,
        day,
    };
};

/**
 * @param {*} deliveryDate
 * Split Date by hypen "-" and returns year, month, day
 * "2020-01-01" returns { year: "2020", "month": "01", "day": "01"}
 */
const splitDateByHyphen = (deliveryDate) => {
    const splitDate = deliveryDate.split('-');
    const year = parseInt(splitDate[0], 10);
    const month = parseInt(splitDate[1], 10) - 1;
    const day = parseInt(splitDate[2], 10);

    return {
        year,
        month,
        day,
    };
};

const getDateObject = ({ year, month, day }) => {
    const dateObj = new Date(year, month, day);
    const dateStr = format(dateObj, 'yyyy-MM-dd');
    return {
        dateStr,
        dateObj,
    };
};

const getDateDesc = (upcharge, surcharge, passportUser) => {
    const totalCharge = parseFloat((parseFloat(surcharge || 0, 10)) + (parseFloat(upcharge || 0, 10)), 10);
    let dateDesc = passportUser ? 'Free Shipping' : 'Std. Shipping';
    if (totalCharge > 0) {
        dateDesc = `${dateDesc} + $${totalCharge.toFixed(2)}`;
    }
    return dateDesc;
};

const getDailyCalendarOptiions = (calendarDates, passportUser) => {
    const shippingOptions = calendarDates.map((dateInfo) => {
        let dateId = '';
        const {
            deliveryDate, deliverySLA, rangeStartDate,
            surcharge, upcharge, calendarDateType, holidayCode,
        } = dateInfo;

        if (deliveryDate) {
            dateId = `deliveryDate=${deliveryDate}`;
        }

        if (deliverySLA) {
            dateId = `${dateId}&&deliverySLA=${deliverySLA}`;
        }

        if (calendarDateType) {
            dateId = `${dateId}&&calendarDateType=${calendarDateType}`;
        }

        if (holidayCode && Array.isArray(holidayCode) && holidayCode.length > 0) {
            dateId = `${dateId}&&holidayCode=${holidayCode[0]}`;
        }

        let dateLabel = '';
        const splitDeliveryDate = splitDateByHyphen(deliveryDate);
        const deliveryDataObj = getDateObject(splitDeliveryDate);

        if (calendarDateType === 'on') {
            dateLabel = format(deliveryDataObj.dateObj, 'EEEE - LLL dd yyyy');
        } else if (calendarDateType === 'between' && rangeStartDate) {
            const splitRangeStartDate = splitDateByHyphen(rangeStartDate);
            const rangeStartDateObj = getDateObject(splitRangeStartDate);

            dateLabel = `Between ${format(rangeStartDateObj.dateObj, 'LLL dd')} and ${format(deliveryDataObj.dateObj, 'LLL dd yyyy')}`;
            dateId = `${dateId}&&calendarStartDate=${rangeStartDate}`;
        }

        const dateDesc = getDateDesc(upcharge, surcharge, passportUser);

        return {
            id: dateId,
            label: dateLabel,
            description: dateDesc,
        };
    });

    return shippingOptions;
};

const getWeeklyCalendarOptiions = (calendarDates) => {
    const shippingOptions = calendarDates.map((dateInfo) => {
        let dateId = '';
        const {
            deliveryDate, deliverySLA,
            holidayCode, calendarDateType,
        } = dateInfo;

        const deliveryDataObj = new Date(deliveryDate);
        const strDeliveryDate = format(deliveryDataObj, 'yyyy-MM-dd');
        if (deliveryDate) {
            dateId = `deliveryDate=${strDeliveryDate}&&calendarStartDate=${strDeliveryDate}`;
        }

        if (deliverySLA) {
            dateId = `${dateId}&&deliverySLA=${deliverySLA}`;
        }

        if (calendarDateType) {
            dateId = `${dateId}&&calendarDateType=${calendarDateType}`;
        }

        if (holidayCode && Array.isArray(holidayCode) && holidayCode.length > 0) {
            dateId = `${dateId}&&holidayCode=${holidayCode[0]}`;
        }

        const dateLabel = `Arrive Week Of ${format(deliveryDataObj, 'LLL dd')}`;

        return {
            id: dateId,
            label: dateLabel,
            description: '',
        };
    });

    return shippingOptions;
};

const getMonthlyCalendarOptiions = (calendarDates) => {
    const shippingOptions = calendarDates.map((dateInfo) => {
        let dateId = '';
        const {
            deliveryDate, deliverySLA,
            holidayCode, calendarDateType,
        } = dateInfo;

        const splitdeliveryData = splitDateBySlash(deliveryDate);
        const deliveryDataObj = getDateObject({
            day: splitdeliveryData.day || '01',
            month: splitdeliveryData.month,
            year: splitdeliveryData.year,
        });

        if (deliveryDate) {
            dateId = `deliveryDate=${deliveryDataObj.dateStr}&&calendarStartDate=${deliveryDataObj.dateStr}`;
        }

        if (deliverySLA) {
            dateId = `${dateId}&&deliverySLA=${deliverySLA}`;
        }

        if (calendarDateType) {
            dateId = `${dateId}&&calendarDateType=${calendarDateType}`;
        }

        if (holidayCode && Array.isArray(holidayCode) && holidayCode.length > 0) {
            dateId = `${dateId}&&holidayCode=${holidayCode[0]}`;
        }

        const dateLabel = `Arrive in ${format(deliveryDataObj.dateObj, 'LLLL yyyy')}`;

        return {
            id: dateId,
            label: dateLabel,
            description: '',
        };
    });

    return shippingOptions;
};

export const getShippingOption = (calendarData, passportUser) => {
    let shippingOptions = [];
    let defaultSelectedOptionId = '';

    if (calendarData?.calendarDates?.length > 0) {
        const { calendarDates, deliveryIndicator } = calendarData;

        if (deliveryIndicator === 'WO') {
            shippingOptions = getWeeklyCalendarOptiions(calendarDates);
        } else if (deliveryIndicator === 'MO') {
            shippingOptions = getMonthlyCalendarOptiions(calendarDates);
        } else {
            shippingOptions = getDailyCalendarOptiions(calendarDates, passportUser);
        }
    }

    if (shippingOptions.length > 0) {
        defaultSelectedOptionId = shippingOptions[0].id;

        return {
            defaultSelectedOptionId,
            shippingOptions,
        };
    }
    return defaultShippingOptionSelection;
};

// Get the total summary from the cart data
const getOrderTotalProduct = (cart) => cart?.totalProduct || 0;
const getOrderTotalShipping = (cart) => cart?.totalShipping?.toFixed(2) || 0;
const getTotalShippingAdjustments = (cart) => cart?.totalShippingAdjustments || 0;
const getTotalAdjustments = (cart) => cart?.totalAdjustments || 0;
const getOrderTotalTax = (cart) => cart?.totalTax || 0;
const getShippingTax = (cart) => cart?.totalShippingTax || 0;

const getGiftCardDiscounts = (cart) => cart.totalGiftCardAmount || '0.00';
export const getOrderTotalBalance = (cart) => cart.cartTotal || 0.00;

const getorderTotalBeforeTaxes = (cart) => cart?.totalBeforeTaxes;
export const getOrderTotalSummaryInfo = (cart) => ({
    totalProduct: getOrderTotalProduct(cart),
    totalShipping: getOrderTotalShipping(cart),
    totalShippingAdjustments: getTotalShippingAdjustments(cart),
    totalAdjustments: getTotalAdjustments(cart),
    totalTax: (getOrderTotalTax(cart) + getShippingTax(cart))?.toFixed(2) || 0,
    orderTotalBalance: getOrderTotalBalance(cart),
    totalGiftCards: getGiftCardDiscounts(cart),
    orderTotalBeforeTaxes: getorderTotalBeforeTaxes(cart),
});

export const getGoogleUnserviceableAddressError = () => ({
    reason: 'SHIPPING_ADDRESS_UNSERVICEABLE',
    message: 'Cannot ship to the selected address',
    intent: 'SHIPPING_ADDRESS',
});

export default {};
