/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import * as actionTypes from './Auth-ActionTypes';

export const clearAuthDependentState = (forceClear) => ({
    type: actionTypes.CLEAR_AUTH_DEPENDENT_STATE,
    data: {
        forceClear,
    },
});

export const triggerInterstitialLogin = (history, containsSubscription, isOnePageCheckoutEnabled) => ({
    type: actionTypes.TRIGGER_INTERSTITIAL_LOGIN,
    history,
    containsSubscription,
    isOnePageCheckoutEnabled,
});

export const addRouteChanges = (path, searchParams) => ({
    type: actionTypes.LISTEN_ROUTE_CHANGES,
    path,
    searchParams,
});

export const logLastVisitedPage = (urls) => ({
    type: actionTypes.LOG_LAST_VISITED_PAGE,
    data: {
        urls,
    },
});

export const setAuthDetails = (authDetails) => ({
    type: actionTypes.SET_AUTH_DETAILS,
    data: authDetails,
});

export const setAccessToken = (accessToken) => ({
    type: actionTypes.SET_ACCESS_TOKEN,
    data: {
        accessToken,
    },
});

export const logGuestSession = (authDetails) => ({
    type: actionTypes.LOG_GUEST_SESSION,
    data: authDetails,
});

export const logUserLoggedOut = (logout) => ({
    type: actionTypes.LOG_USER_LOGGED_OUT,
    data: {
        logout,
    },
});

export const logUserLoggedInSuccess = (authDetails) => ({
    type: actionTypes.LOG_USER_LOGGED_IN_SUCCESS,
    data: authDetails,
});

export const logUserLoggedInFailed = () => ({
    type: actionTypes.LOG_USER_LOGGED_IN_FAILED,
});

export const logHydraidUserSession = (authDetails) => ({
    type: actionTypes.LOG_HYDRAID_USER_SESSION,
    data: authDetails,
});

export const logUserLoginInSessionReady = () => ({
    type: actionTypes.LOG_USER_LOGIN_SESSION_READY,
});

export const logUserSessionReady = (authDetails) => ({
    type: actionTypes.LOG_USER_SESSION_READY,
    data: authDetails,
});
